<template>
    <div>
        <div v-if="!addOrEditReady">
            <LoadingSpinner class="col-12" />
        </div>

        <div v-if="addOrEditReady">
            <form @submit.prevent="checkForm">
                <input type="hidden" id="articles_id" class="form-control" v-model="form.articles_id">
                <div class="row">
                    <div class="col-12" v-if="form_message !== ''">
                        <ErrorAlert :messageI18n="form_message" />
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles" class="col-form-label">{{ $t("compta.form.lier_articles") }} *</label>

                            <e-select
                                v-model="form.articles"
                                id="articles"
                                track-by="name"
                                label="name"
                                :placeholder="labelTitle"
                                :deselectLabel="deselectLabel"
                                :selectedLabel="selectedLabel"
                                :selectLabel="enter_to_select"
                                :options="articles"
                                :searchable="true"
                                :allow-empty="true"
                                :loading="isLoading"
                                :show-labels="false"
                                :multiple="true"
                                :hideSelected="true"
                                :class="{ 'is-invalid': errors && errors.indexOf('Articles') > -1 }"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label class="col-form-label">{{ $t("compta.form.article_defaut") }}</label>

                            <e-select
                                v-model="form.default"
                                track-by="name"
                                label="name"
                                :placeholder="labelTitle"
                                :deselectLabel="deselectLabel"
                                :selectedLabel="selectedLabel"
                                :selectLabel="enter_to_select"
                                :options="form.articles"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="true"
                                :preselectFirst="false"
                                :multiple="true"
                                :class="{ 'is-invalid': errors && errors.indexOf('Default') > -1 }"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label class="col-form-label">{{ $t("compta.form.article_triggered") }}</label>

                            <e-select
                                v-model="form.triggered"
                                track-by="name"
                                label="name"
                                :placeholder="labelTitle"
                                :deselectLabel="deselectLabel"
                                :selectedLabel="selectedLabel"
                                :selectLabel="enter_to_select"
                                :options="form.articles"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="true"
                                :preselectFirst="false"
                                :class="{ 'is-invalid': errors && errors.indexOf('Default') > -1 }"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                        <b-form-checkbox v-model="form.quantifiable">
                            {{ $t('acte.quantifiable') }}
                        </b-form-checkbox>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import Accounting from "@/mixins/Accounting.js"
    import ActeType from "@/mixins/ActeType.js"
    import Article from "@/mixins/Article.js"

    export default {
        name: "ActesTypeArticlesAjout",
        props: ['accountingplan_id', 'actestype_id', 'addOrEditReady', 'actes_type_articles'],
        mixins: [Navigation, Accounting, Tools, ActeType, Article],
        data () {
            return {
                labelTitle: this.getTrad("invoice.ligne_vierge_search_article"),
                labelNotFound: this.getTrad("invoice.ligne_vierge_no_article_found"),
                labelSearchPlaceholder: this.getTrad("invoice.ligne_vierge_rechercher"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                selectedLabel: this.getTrad("global.selected_label"),
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                articles: [],
                isLoading: false,

                form_message: "",
                form: {
                    articles: [],
                    default: [],
                    triggered: null,
                    quantifiable: false,
                },
                default_form: {
                    articles: [],
                    default: [],
                    triggered: null,
                    quantifiable: false,
                },
                errors: [],
                loading: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
                this.form_message = ""
                this.$emit('update:addOrEditReady', false)

                this.form = this.default_form

                let actestype = {}
                if(this.actes_type_articles) {
                    actestype = this.actes_type_articles.filter(actestype => actestype.actestype_id == this.actestype_id)[0]
                } else {
                    // IL FAUT ALLER CHERCHER JUSTE L'ACTETYPE EN BDD AVEC LES ARTICLES ASSOCIÉ
                }

                if(Object.keys(actestype).length > 0) {
                    if(actestype.articles !== undefined) {
                        if(actestype.articles.length > 0) {
                            let actestype_article = {}
                            actestype.articles.forEach((article) => {
                                const option = {
                                    name: `${article.articles_label} (${article.articles_code})`,
                                    id: article.articles_id
                                }

                                this.form.articles.push(option)

                                actestype_article = article.actes_type.filter(actestype => actestype.actestype_id == this.actestype_id)[0]
                                if(actestype_article) {
                                    this.form.quantifiable = actestype_article.pivot.actestypearticles_quantifiable === 1 ? true : false
                                    if(actestype_article.pivot.actestypearticles_default) {
                                        this.form.default.push(option)
                                    }
                                    if(actestype_article.pivot.actestypearticles_triggered) {
                                        this.form.triggered = option
                                    }
                                }
                            })
                        }
                    }
                }

                if(Object.keys(this.articles).length == 0) {
                    this.articles = []
                    const articles = await this.loadArticles(this.accountingplan_id)
                    if(articles.length > 0) {
                        articles.forEach((article) => {
                            this.articles.push({name: article.articles_label+' ('+article.articles_code+')', id: article.articles_id })
                        })
                    }
                }

                this.$emit('update:addOrEditReady', true)
                this.loading = false
            },
            checkForm: async function () {
                this.form_message = ""
                this.errors = []
                let articles = []

                if(this.form.articles.length > 0) {
                    this.form.articles.forEach((article) => {
                        articles.push(article.id)
                    })
                }

                if(this.errors.length > 0)
                {
                    return false
                }

                let result = await this.addLienActesTypeArticles(
                    this.actestype_id,
                    this.accountingplan_id,
                    articles,
                    this.form.default,
                    this.form.triggered,
                    this.form.quantifiable
				);

                if(result) {
					this.$sync.force(true)
                    return true
                } else {
                    this.form_message = "error.LEP"
                    return false
                }
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        },
        watch: {
            'form.articles'(newval) {
                if(!this.loading && !this.form.default) {
                    if(newval) {
                        if(newval.length == 1) {
                            // this.form.default = newval[0]
                            return
                        }
                    }
                }
            }
        }
    }
</script>
